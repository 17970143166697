import CustomSwiper from "@/components/Swiper/CustomSwiper";
import CustomSwiperSlide from "@/components/Swiper/CustomSwiperSlide";
import useTestimonials from "@/hooks/useTestimonials";
import Arrow from "@/assets/icons/arrow-right.svg";

import { useState } from "react";

export default function Testimonials({
  customStyles: { bgColor, txtColor, authorColor },
}) {
  const testimonials = useTestimonials();



  const [ref, setRef] = useState();
  const [refSecond, setRefSecond] = useState();

  const handleNext = () => {
    ref?.current?.swiper?.slideNext();
    refSecond?.current?.swiper?.slideNext();
  };

  const handlePrev = () => {
    ref?.current?.swiper?.slidePrev();
    refSecond?.current?.swiper?.slidePrev();
  };

  const getRef = (ref) => {
    setRef(ref);
  };
  const getRefSecond = (ref) => {
    setRefSecond(ref);
  };
  return (
    <div className="relative latest-testimonials container w-full m-auto testimonials pt-20 md:pt-32 sm:pb-12 md:pb-20 mb-10 sm:mb-0">
      <CustomSwiper
        view={{ desktop: 1.05, laptop: 1.05, md: 1, mobile: 1 }}
        space={28}
        getRef={getRef}
      >
        {testimonials.map((item) => (
          <CustomSwiperSlide key={item?.id} styles={{ paddingTop: "80px" }}>
            <div className="w-full relative flex justify-center">
              <div className="bg-purple w-28 h-28 md:w-40 md:h-40 flex items-center justify-center absolute top-[-80px] z-10 p-4">
                <img
                  src={item?.logo ?? ""}
                  alt="company logo"
                  width={114}
                  height={82}
                  className="w-full h-full object-contain"
                />
              </div>
              <div
                className={`item ${txtColor} ${bgColor} min-h-[543px] h-full flex flex-col px-[80px] py-16 lg:px-[83px] w-full text-center gap-6 justify-center`}
              >
                <span className="italic font-400 text-xl lg:text-3xl mb-[40px] lg:!leading-[45px]">
                  {item?.feedback}
                </span>
                <span
                  className={`${authorColor} font-400 text-xl lg:text-3xl`}
                >
                  {item?.author}
                </span>
              </div>
            </div>
          </CustomSwiperSlide>
        ))}
      </CustomSwiper>
      <div
        onClick={handlePrev}
        role="button"
        aria-label="Previous"
        className={`z-[1] bg-white left-[0px]  xl:left-[30px] hover:opacity-[0.5] w-12 h-12 sm:w-16 sm:h-16 group/item flex rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer absolute top-[50%]`}
      >
        <Arrow
          className={`fill-[#150235] rotate-180 w-[30px] h-[30px] sm:w-[40px] sm:h-[40px`}
        />
      </div>
      <div
        onClick={handleNext}
        role="button"
        aria-label="Next"
        className={`z-[1] bg-white right-[0px]  md:right-[0px] lg:right-[30px] xl:right-[6%] hover:opacity-[0.5] w-12 h-12 sm:w-16 sm:h-16 group/item flex rounded-full md:w-[90px] md:h-[90px] items-center justify-center cursor-pointer absolute top-[50%]`}
      >
        <Arrow
          className={`fill-[#150235] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px`}
        />
      </div>
    </div>
  );
}
